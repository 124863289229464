import { useEffect, useState } from "react";

function ManageLeague() {
    const [status, setStatus] = useState("");

    useEffect(() => {
        fetch("https://mm-seasons.mildmania.net/has-ongoing-league")
            .then(response => response.json())
            .then(data => {
                if (data.result !== true) {
                    setStatus("Not active");
                } 
                setStatus("Active");
            })
            .catch(error => console.error(error));
    }, []);

    const seasonStart = () => {
        fetch("https://mm-seasons.mildmania.net/start-season", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                },
                body: '{ "duration": 10000 }',
            })
            .then(response => response.json())
            .then(data => {
                if (data.result !== true) {
                    setStatus("Not active");
                }
                setStatus("Active");
            })
            .catch(error => console.error(error));
    };

    const seasonEnd = () => {
        fetch("https://mm-seasons.mildmania.net/end-season", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                },
                body: '{ "duration": 10000 }',
            })
            .then(response => response.json())
            .then(data => {
                if (data.result === true) {
                    setStatus("Not active");
                }
                setStatus("Active");
            })
            .catch(error => console.error(error));
    };



    return (
        <div className="container mx-auto">
            <div className="flex flex-wrap sm:px-20">
                <div className="w-1/2 mt-10 mx-10">
                    <div className="p-2">
                        <h1 className="font-bold text-3xl mb-2">Manage League</h1>
                        <h2 className="card-subtitle text-xl mb-2 text-muted">Status: {status}</h2>
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={seasonStart}>Start Season</button>
                        <button className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded mx-10" onClick={seasonEnd}>End Season</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ManageLeague;