import React from "react";

const NotFound = () => (
  <div
    className="flex items-center justify-center w-screen h-screen bg-gradient-to-r from-gray-50 to-gray-100">
    <div className="px-40 py-20 bg-white rounded-md shadow-xl">
      <div className="flex flex-col items-center">
        <h1 className="text-3xl font-bold text-red-500">404</h1>

        <h6 className="mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl">
          Page not found
        </h6>

        <p className="mb-8 text-center text-gray-500 md:text-lg">
          The page you're looking for doesn't exist.
        </p>
      </div>
    </div>
  </div>
);

export default NotFound;
