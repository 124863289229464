import { useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";

function Login() {
    const auth = useAuth();

    const [userID, setUserID] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [token, setToken] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        if (userID === "" || password === "") {
            setError("Please fill in all fields");
            return;
        }
        fetch("https://auth.mildmania.net/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ user: userID, pass: password, project: "mm" }),
        }).then((res) => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 401) {
                    setError("Invalid username or password");
                } else if (res.status === 403) {
                    setError("You do not have permission to access this page");
                }
            }).then((data) => {
                if (data) {
                    auth.setAuth(data.token, userID);
                    setError("");
                } else {
                    setToken("");
                }
            });
    };

    return (
        <div className="block mx-auto w-screen mt-20 bg-white rounded-lg shadow sm:max-w-md sm:mt-40">
            <div className="p-6 space-y-4 sm:p-8">
                <h1 className="text-3xl">Login</h1>
                <form className="space-y-2"  onSubmit={handleSubmit}>
                    <label className="flex text-sm font-medium text-gray-900" htmlFor="userID">Username</label>
                    <input className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" type="text" id="userID" onChange={(e) => setUserID(e.target.value)} />
                    <label className="flex text-sm font-medium text-gray-900" htmlFor="password">Password</label>
                    <input className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" type="password" id="password" onChange={(e) => setPassword(e.target.value)} />
                    <div className="flex items-center justify-between mt-4">
                    <button className="w-full text-white my-4 bg-blue-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center" type="submit">Login</button>
                    </div>
                </form>
                <div className="text-red-600 text-md">{error}</div>
            </div>
        </div>
    );
}

export default Login;