export const fetchJson = async (url, init = {}) => {
    const res = await fetch(url, init);
    if (!res.ok) {
      throw new Error(`${res.status}: ${await res.text()}`);
    }
    return res.json();
  };
  
  export const fetchAndSetAll = async (collection) => {
    const allData = await Promise.all(
      collection.map(({ url, init }) => fetchJson(url, init))
    );
  
    collection.forEach(({ setter }, i) => {
      setter(allData[i]);
    });
  };