import { useState } from "react";
import { useParams } from "react-router-dom";

function EditPlayer() {
    const { id } = useParams();
    const [player, setPlayer] = useState({});




    return (
        <div>
            <h1 className="text-3xl">Edit Player-{id}</h1>
        </div>
    );
}

export default EditPlayer;