import { useEffect, useState } from "react";
import { fetchAndSetAll } from "../helpers/fetch-helpers";

function Leagues() {
    const [dateInfo, setDateInfo] = useState({});
    const [leagueInfo, setLeagueInfo] = useState({});

    useEffect(() => {
        fetchAndSetAll([
            {
                url: "https://mm-seasons-live.mildmania.net/key?k=global-state",
                setter: setDateInfo,
            },
            {
                url: "https://mm-seasons-live.mildmania.net/get-leagues?season=",
                setter: setLeagueInfo,
            },
        ]).catch((err) => {
            console.error(err);
        });

    }, []);

    if (!dateInfo || !leagueInfo) {
        return null;
    }

    const activeStartDate = dateInfo.started;
    const activeEndDate = dateInfo.ends_in;

    const now = Math.floor(Date.now() / 1000);
    const percentage = (now - activeStartDate) / (activeEndDate - activeStartDate) * 100;
    const formattedPercentage = percentage.toFixed(2);
    const progress = formattedPercentage + "%";

    const leagues = leagueInfo.result;

    if (!leagues) {
        return null;
    }

    var leagueList = [];
    for (let values in leagues) {
        let league = leagues[values];
        leagueList.push(league);
    }

    const sortDesc = (a, b) => {
        if (a.season_index < b.season_index) {
            return 1;
        } else {
            return -1;
        }
    };

    const sortedLeagues = leagueList.sort(sortDesc);
    
    return (
        <div>
            <header>
                <div className="container mx-auto">
                    <div className="w-full bg-gray-200 rounded-full h-8 ">
                        <div className="mt-8 p-4 px-4 flex items-center justify-center" id="progress" style={{width: progress}}>
                            { formattedPercentage > 9 ? 
                            <div className="text-white font-bold text-center">{progress}</div> 
                            : <div className="text-white font-bold text-center">{formattedPercentage}</div> }
                        </div>
                    </div>
                </div>
            </header>
            <div className="container mx-auto">
                {sortedLeagues ? (
                    <div className="flex flex-wrap mt-10">
                        {sortedLeagues.map((league, i) => (
                            <div className="w-full md:w-1/2 xl:w-1/3 p-6 flex flex-col" key={i}>
                                    { i === 0 || league.season_index !== sortedLeagues[i - 1].season_index ? (
                                        <div className="w-full mb-10">
                                        <h5 className="text-2xl text-gray-900 font-bold leading-none mb-3">
                                            Season {league.season_index}, {league.start} - {league.end}
                                        </h5>
                                        </div>
                                    ) : null }
                                <div className="bg-slate-300 border-b-4 rounded-t-lg p-5">
                                    <div className="flex-1">
                                        <p className="uppercase text-gray-900 md:text-lg -semibold">League-{league.season_index}-{league.league_index}</p>
                                        <div className="flex items-center">
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-slate-200 border rounded shadow">
                                    <div className="border-b p-3">
                                        <table className="table-auto border-collapse w-full shadow-none">
                                            <thead>
                                                <tr>
                                                    <th className="text-gray-900 font-medium px-4 py-2">Player Name</th>
                                                    <th className="text-gray-900 font-medium px-4 py-2">Score</th>
                                                    <th className="text-gray-900 font-medium px-4 py-2">Rewarded</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {league.players.sort((a, b) => b.score - a.score).map((player, i) => (
                                                <tr>
                                                    <td className="border px-4 py-2" key={player.id}><a href={`/player/${player.id}`}>{player.name}</a></td>
                                                    <td className="border px-4 py-2">{player.score}</td>
                                                    <td className="border px-4 py-2">{league.rewarded.includes(player.id) ? 
                                                    (<span className="w-1/2 flex items-center justify-center text-white text-sm font-semibold bg-green-600 p-1 rounded rounded-xl">Yes</span>) 
                                                    : (<span className="text-red-500"> </span>)}
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="text-center">No leagues found</div>
                )}
                </div>
            </div>
    );
}

export default Leagues;