import { useState } from 'react';
import Login from '../components/Login';

const useAuth = () => {
  const getAuth = () => {
    const authString = localStorage.getItem('mm_dashboard_token');
    if (!authString || authString.length < 1) return undefined;
    return JSON.parse(authString);
  };

  const [auth, setAuth] = useState(getAuth());

  const saveAuth = (userAuth, user) => {
    fetch('https://auth.mildmania.net/validate-token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: userAuth }),
    }).then((res) => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 401) {
        return <Login />;
      } else if (res.status === 403) {
        return <Login permission={false} />;
      }
    }).then((data) => {
      if (data.msg === 'ok') {
        console.log('token is valid');
        localStorage.setItem('mm_dashboard_token', JSON.stringify(userAuth));
        localStorage.setItem('mm_dashboard_user', JSON.stringify(user));
        setAuth(userAuth);
        window.location = '/';
        return true;
      } else {
        return false;
      }
    });
  };


  return {
    setAuth: saveAuth,
    auth,
  };
};

export default useAuth;
