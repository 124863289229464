import { BrowserRouter as Router, Link, Route, Routes } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import { useEffect, useState } from "react";

import EditPlayer from "./components/EditPlayer";
import Leagues from "./components/Leagues";
import Login from "./components/Login";
import Logs from "./components/Logs";
import ManageLeague from "./components/ManageLeague";
import Player from "./components/Player";
import Players from "./components/PlayerList";
import NotFound from "./components/NotFound";
import Welcome from "./components/Welcome";
import Levels from "./components/Levels";


function App() {
    const auth = useAuth();
    const token = localStorage.getItem("mm_dashboard_token");
    const user = localStorage.getItem("mm_dashboard_user");
    const userID = JSON.parse(user);

    const [active, setActive] = useState(false);

    const logout = () => {
        localStorage.removeItem("mm_dashboard_token");
        localStorage.removeItem("mm_dashboard_user");
        console.log("token deleted, good bye");
        window.location = "/";
    };

    const links = {
        Overview: {
            pathname: "/",
            component: Welcome,
        },
        Players: {
            pathname: "/players",
            component: Players,
        },
        Leagues: {
            pathname: "/leagues",
            component: Leagues,
        },
        Levels: {
            pathname: "/levels",
            component: Levels,
        },
        Logs: {
            pathname: "/logs",
            component: Logs,
        },
    };

    if (!auth?.auth) {
        return <Login />
    }

    const x = "sm:mr-8 py-6 px-2 bg-transparent text-white text-sm sm:text-lg font-normal hover:border-b-4 hover:border-white";
    const y = "sm:mr-8 py-6 px-2 bg-transparent text-white text-sm sm:text-lg font-extrabold hover:border-b-4 hover:border-white"

    return (
        <Router>
            <div className="divide-y">
                <div className="flex justify-between p-4 sm:px-10 bg-black">
                    <nav className="px-2 py-2.5 bg-black">
                        {Object.keys(links).map((key, i) => (
                            <Link 
                            className={links[key].pathname === window.location.pathname ? active ? y : y : active ? x : x}
                            key={i} 
                            onClick={() => setActive(!active)}
                            to={links[key].pathname}>
                                {key}{" "}
                            </Link>
                        ))}
                    </nav>
                    <button onClick={logout} className="py-2 pr-1 p sm:pr-3 sm:pl-3 bg-transparent text-white text-sm sm:text-lg font-small border border-2 rounded hover:bg-white hover:text-black">
                        {auth ? "Logout " + userID : "Login"}
                    </button>
                </div>
                <Routes>
                    <Route path="/player/:id" element={<Player />} />
                    <Route path="/edit-player/:id" element={<EditPlayer />} />
                    <Route path="/manage-league/:id" element={<ManageLeague />} />
                    <Route 
                        path={links.Overview.pathname}
                        element={<links.Overview.component />}
                    />
                    <Route
                        path={links.Players.pathname}
                        element={<links.Players.component />}
                    />
                    <Route
                        path={links.Leagues.pathname}
                        element={<links.Leagues.component />}
                    />
                    <Route
                        path={links.Levels.pathname}
                        element={<links.Levels.component />}
                    />
                    <Route
                        path={links.Logs.pathname}
                        element={<links.Logs.component />}
                    />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
