import { useEffect, useState } from "react";

function PlayerList() {
    const [players, setPlayers] = useState([]);

    const getPlayer = () => {
        var q = document.getElementById("search").value;
        var url = "https://mayor-match-api.mildmania.net/search?q=";
        fetch(url + q)
            .then(response => response.json())
            .then(data => {
                setPlayers(data);
            })
            .catch(error => console.error(error));
    };

    console.log(players)


    return (
        <div className="container mx-auto">
            <div className="flex flex-wrap sm:px-20">
                <div className="w-1/2 mt-10 mx-10">
                    <div className="p-2">
                        <input
                            type="text"
                            id="search"
                            placeholder="Search player name"
                            className="w-1/2 border-2 border-gray-300 p-2 rounded-lg"
                        />
                        <button
                            onClick={getPlayer}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-10"
                        >
                            Search
                        </button>
                    </div>
                </div>
                <div className="w-1/2 mt-10 mx-10">
                        <table className="table-auto">
                        <thead>
                            {players.length !== 0 ? (
                            <tr>
                                <th className="px-4 py-2">Name</th>
                                <th className="px-4 py-2">ID</th>
                            </tr>
                             ) : (
                            <div className="p-2">
                                <h2 className="card-subtitle text-lg mb-2 text-muted">Search for a player list</h2>
                            </div>
                            )}
                        </thead>
                        {players.map((player) => (
                        <tbody>
                            <tr>
                                <td className="border px-4 py-2"><a href={"/player/" + player[1]}>{player[0]}</a></td>
                                <td className="border px-4 py-2"><a href={"/player/" + player[1]}>{player[1]}</a></td>
                            </tr>
                        </tbody>
                            ))}
                    </table>
                </div>
            </div>
        </div>
    );
}

export default PlayerList;