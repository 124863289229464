import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function Player() {
    const { id } = useParams();
    const [player, setPlayer] = useState({});

    useEffect(() => {
        fetch(`https://mm-live.mildmania.net/read-player-data?id=${id}`)
            .then((res) => res.json())
            .then((data) => {
                setPlayer(data);
            })
            .catch((err) => {
                console.error(err);
            });
    }, [id]);

    if (!player) {
        return null;
    }
    
    if (player.UserGenericData) {
        var playerName = player.UserGenericData.Trackables[0].UserName;
    }

    if (player.PowerUpInventoryData) {
        var powerUps = player.PowerUpInventoryData.Trackables;
    }

    if (player.BoosterInventoryData) {
        var boosters = player.BoosterInventoryData.Trackables;
    }

    if (player.CurrencyInventoryData) {
        var currencies = player.CurrencyInventoryData.Trackables;
    }
        
    console.log(player)

    return (
        <div className="container mx-auto">
            <div className="flex flex-wrap sm:px-20">
                <div className="w-1/2 mt-10 mx-10">
                    <div className="p-2">
                        {currencies ? (
                            <div>
                        <h1 className="font-bold text-4xl mb-2">{playerName}</h1>
                        <h2 className="card-subtitle mb-2 text-muted">{id}</h2>
                        {currencies.map((currency, i) => (
                            <div key={i} className="py-2">
                                <div className="text-black"><a className="font-semibold text-xl">{currency.TrackID}: </a><a className="font-medium text-md">{currency.Count}</a></div>
                            </div>
                        ))}
                        </div>
                        ) : (
                            null
                        )}
                        {/* <h2 className="card-title">Power Ups</h2>
                            {powerUps ? powerUps.map((powerUp) => (
                                <div key={powerUp.TrackID}>
                                    <h3 className="card-subtitle mb-2 text-muted">Power Up Name: {powerUp.TrackID}</h3>
                                    <h3 className="card-subtitle mb-2 text-muted">Power Up Count: {powerUp.Count}</h3>
                                </div>
                            )) : null} */}

                    </div>
                </div>
                <div className="flex flex-col justify-start items-right mt-14 sm:mx-20">
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        <a href={`/edit-player/${id}`}>Edit Player</a>
                    </button>
                </div>
                
                <div className="card mt-10 mx-10 w-1/3">
                        {powerUps ? (
                            <div className="card-body">
                                <div className="card-title">Power Ups</div>
                            {powerUps.map((powerUp, i) => (
                               <div key={powerUp.TrackID}>
                                    <h3 className="card-subtitle mb-2 text-muted">Name: {powerUp.TrackID}</h3>
                                    <h3 className="card-subtitle mb-2 text-muted">Count: {powerUp.Count}</h3>
                                </div>
                            ))}
                            </div>
                        ) : (
                            null
                        )}
                </div>
                
                <div className="card mt-10 mx-10 w-1/3">
                        {boosters ? (
                            <div className="card-body">
                                <div className="card-title">Boosters</div>
                            {boosters.map((booster, i) => (
                               <div key={booster.TrackID}>
                                    <h3 className="card-subtitle mb-2 text-muted">Name: {booster.TrackID}</h3>
                                    <h3 className="card-subtitle mb-2 text-muted">Count: {booster.Count}</h3>
                                </div>
                            ))}
                            </div>
                        ) : (
                            null
                        )}
                </div>
            </div>
        </div>
    );
}

export default Player;